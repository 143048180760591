<div class="fade-in-content">
  <div class="pb-3">
      <p class="article-header pb-1 text-left">Фадеева Галина Васильевна<span class="p3">, генеральный директор</span></p>
      <img class="float-left" style="margin: 0px 20px 0px 0px; height:200px" src="../../images/heads/fadeeva.jpg" />
      <p class="article-text">
Доктор экономических наук, профессор. Заслуженный экономист России, Почетный строитель России. Инженер-гидротехник. Работала в тресте Сельхозводстрой в проектно-сметной группе, мастером на строительстве очистных сооружений, главным технологом на заводе ЖБИ; ведущим инженером ОКСа, начальником отдела ценообразования и смет ГлавОмскпромстроя. С 1994 года - генеральный директор Сибирского центра ценообразования в строительстве, промышленности и энергетике. Член Экспертного совета по ценообразованию и сметному нормированию в строительстве при ФАУ «Главгосэкспертиза России».      
      </p>
  </div>
  <div class="row w-100 pb-3 mx-0"><div class="col px-0">
      <p class="article-header pb-1 text-left">Бендюков Сергей Геннадьевич<span class="p3">, исполнительный директор</span></p>
      <img class="float-right" style="margin: 0px 0px 0px 20px; height:200px" src="../../images/heads/bendukov.jpg" />
      <p class="article-text">
Инженер-гидротехник, работал инженером производства в Т.О.О. «Электросвязьстрой». С 2004 по 2019 год инженер-сметчик, главный специалист, начальник сметного отдела Сибирского центра ценообразования, с 2020 года исполнительный директор
      </p>
  </div></div>

  <div class="pb-3">
      <p class="article-header pb-1 text-left">Яровая Наталья Борисовна<span class="p3">, зам. генерального директора</span></p>
      <img class="float-left" style="margin: 0px 20px 0px 0px; height:200px" src="../../images/heads/yarovaya.jpg" />
      <p class="article-text">
Инженер-строитель, работала инженером-сметчиком в проектно-технологическом бюро «Омскоблавтотранс», с 1994 года - главный специалист, начальник отдела сметного нормирования, заместитель генерального директора Сибирского центра ценообразования. Курирует вопросы сметного ценообразования в строительстве и жилищно-коммунальном комплексе. Занимается разработкой и внедрением региональных программ капитального ремонта многоквартирных домов.
      </p>
  </div>
<!--
  <div class="pb-5">
      <p class="article-header pb-1 text-left">Гель Константин Викторович<span class="p3">, зам. генерального директора</span></p>
      <img class="float-right" style="margin: 0px 0px 0px 20px; height:200px" src="../../images/heads/gel.jpg" />
      <p class="article-text">
Инженер-механик. Работал начальником РММ службы спецтраспорта ОАО «Авиакомпания «Омскавиа». С 2005 года - главный специалист, начальник отдела технологического оборудования, заместитель генерального директора Сибирского центра ценообразования. Курирует разработку сметно-нормативных баз на ремонт технологического и энергетического оборудования.
      </p>
  </div>
-->
</div>